import { buildQuery } from "@/utils.js";

export async function getNumAlarms(type) {
  const url_query = buildQuery({
    type: type,
  });

  const url = "/api/alarms/count?" + url_query;
  const response = await fetch(url, {
    method: "GET",
  });
  if (response.status == 200) {
    return response.json();
  } else {
    throw new Error("Number of alarms not found");
  }
}

export function getAlarms(clientId, notifiedStatus, solvedStatus, alarmType) {
  const url_query = buildQuery({
    notified: notifiedStatus,
    solved: solvedStatus,
    alarmType: alarmType,
    clientId: clientId
  });
  const url = "/api/alarms/alarms?" + url_query;
  return fetch(url).then((response) => {
    if (response.ok) {
      return response.json();
    } else {
      throw Error(response.statusText);
    }
  });
}

export async function getAlarm(id) {
  const url = "/api/alarms/alarm/" + id;
  const response = await fetch(url, {
    method: "GET",
  });
  if (response.status == 200) {
    return response.json();
  } else {
    throw new Error("The alarm entered does not exist");
  }
}

export async function getActiveAlarms() {
  const url = "/api/alarms/active-alarms";
  const response = await fetch(url, {
    method: "GET",
  });
  if (response.status == 200) {
    return response.json();
  } else {
    throw new Error("Alarms not found");
  }
}

export function getMachineAlarms(idMachine) {
  const url = "/api/alarms/machine-alarms/" + idMachine;
  return fetch(url)
    .then((response) => {
      return response.json();
    })
    .then((data) => {
      return data;
    });
}

export function getMachineNextMaintenance(idMachine) {
  const url = "/api/alarms/machine-next-maintenance/" + idMachine;
  return fetch(url)
    .then((response) => {
      return response.json();
    })
    .then((data) => {
      return data;
    });
}

export function getMachinePastMaintenance(idMachine) {
  const url = "/api/alarms/machine-past-maintenance/" + idMachine;
  return fetch(url)
    .then((response) => {
      return response.json();
    })
    .then((data) => {
      return data;
    });
}

export async function getSolvedAlarmsLastYear() {
  const url = "/api/alarms/solved-alarms-last-year";
  const response = await fetch(url, {
    method: "GET",
  });
  if (response.status == 200) {
    return response.json();
  } else {
    throw new Error("Error getting alarms");
  }
}

export async function sendAlert(alarm_id) {
  const url = "/api/alarms/alert";
  const response = await fetch(url, {
    method: "POST",
    body: JSON.stringify({ alarm_id: alarm_id }),
  });
  if (response.status == 200) {
    return response.json();
  } else {
    throw new Error("Error sending alarm");
  }
}

export async function resetMaintenance(alarmId, maintenanceActionsList, observations) {
  const url = "/api/alarms/reset-maintenance/" + alarmId;
  const response = await fetch(url, {
    method: "POST",
    body: JSON.stringify({ maintenanceActionsList: maintenanceActionsList, observations: observations }),
  });
  if (response.status == 200) {
    return response.json();
  } else {
    throw new Error("Error sending alarm");
  }
}

export async function resetAlarm(alarmId) {
  const url = "/api/alarms/reset-alarm/" + alarmId;
  const response = await fetch(url, {
    method: "POST",
  });
  if (response.status == 200) {
    return response.json();
  } else {
    throw new Error("Error sending alarm");
  }
}

export async function resetAlarmTeltonika(imei, command_number) {
  const url = "/api/alarms/reset-alarm-teltonika";
  const response = await fetch(url, {
    method: "POST",
    body: JSON.stringify({ imei: imei, command_number: command_number }),
  });
  if (response.status == 200) {
    return response.json();
  } else {
    throw new Error("Error sending alarm");
  }
}

export async function getMaintenanceActionsHistory(alarmNotificationId) {
  const url = "/api/alarms/maintenance-actions-history/" + alarmNotificationId;
  const response = await fetch(url, {
    method: "GET",
  });
  if (response.status == 200) {
    return response.json();
  } else {
    throw new Error("Error getting alarms");
  }
}

export async function getAlarmNotifiedMessages(alarmNotificationId) {
  const url = "/api/alarms/notified-alarms/" + alarmNotificationId;
  const response = await fetch(url, {
    method: "GET",
  });
  if (response.status == 200) {
    return response.json();
  } else {
    throw new Error("Error getting alarms");
  }
}
